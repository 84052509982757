import React, {useImperativeHandle, useState} from "react";
import Box from "@mui/material/Box";
import Draggable, {DraggableData} from "react-draggable";

interface FloatHorizontalScrollProps {
  scrollWidth: number
  thumbWidth: number
  onScroll(position: number): void
}

const FloatHorizontalScroll = React.forwardRef((props: FloatHorizontalScrollProps, ref) => {
  const nodeRef = React.useRef(null)
  const [state, setState] = useState<DraggableData>()

  const position = {
    x: state?.x ?? 0,
    y: 0
  }

  const onDrag = (e: any, ui: DraggableData) => {
    e.preventDefault()
    e.stopPropagation()

    const left = ui.x / (props?.scrollWidth - props?.thumbWidth)
    setState(ui)

    props.onScroll(left)
  }

  const setPosition = (x: number) => {
    setState({...state as DraggableData, x})
  }

  useImperativeHandle(ref, () => ({
    setPosition
  }));

  if (props?.thumbWidth === props?.scrollWidth) {
    return null
  }

  return <Box
    ref={ref}
  >
    <Box
      sx={{
        backgroundColor: 'rgba(0,0,0,0.05)',
        width: props?.scrollWidth,
        height: 16
      }}
    >
      <Draggable
        nodeRef={nodeRef}
        position={position}
        bounds="parent"
        axis="x"
        onDrag={onDrag}
        // onStop={onDrag}
      >
        <Box
          ref={nodeRef}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.55)',
            width: props?.thumbWidth,
            height: 16,
            cursor: 'pointer'
          }}
        />
      </Draggable>
    </Box>

  </Box>
})

export default FloatHorizontalScroll
