import Box from "@mui/material/Box";
import React, {PropsWithChildren} from "react";
import {IVrpTable} from "./VrpTable";
import {createPortal} from "react-dom";
import Paper from "@mui/material/Paper";
import FloatHorizontalScroll from "../crud/list/datagrid/FloatHorizontalScroll";

interface VrpTableWithScrollbarProps extends PropsWithChildren<IVrpTable> {
    floatScroll: Record<string, any>
}

const styles = {
    scrollbarStyle: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        scrollbarWidth: 'thin', // для Firefox
        scrollbarColor: '#888 #f2f2f2', // для Firefox
        msOverflowStyle: 'none', // для IE и Edge
        '&::-webkit-scrollbar': { // для Chrome, Safari и Opera
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e6e6e6'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(136,136,136,0.8)',
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: 'rgba(85,85,85,0.8)'
            }
        }
    }
};

const VrpTableWithScrollbar = (props: VrpTableWithScrollbarProps) => {
    if (props.showScroll && props.scrollContainer === 'table') {
        return <Box
            sx={styles.scrollbarStyle}
        >
            {props.children}
        </Box>
    }

    return <>
        {props.children}

        {props.showScroll && props.scrollContainer === 'page' && <Portal frame={props.floatScroll.frame} handleScroll={props.floatScroll.handleScroll} containerRef={props.floatScroll.containerRef} floatScrollRef={props.floatScroll.floatScrollRef}/>}
    </>
}

const Portal = (props: Record<string, any>) => {
    return createPortal(<Paper
        id={'floatHorizontalScrollContainer'}
        variant={'elevation'}
        sx={{
            position: 'fixed',
            bottom: 20,
            left: '50%',
            opacity: 0.7,
            marginLeft: `-${Math.ceil(props.frame?.scrollWidth/2)}px`,
            overflow: 'hidden',
            '&:hover': {
                opacity: 1,
            }
        }}
    >
        <FloatHorizontalScroll
            ref={props.floatScrollRef}
            scrollWidth={props.frame?.scrollWidth}
            thumbWidth={props.frame?.thumbWidth}
            onScroll={props.handleScroll}
        />
    </Paper>, document.body)
}

export default VrpTableWithScrollbar
