import Button from "@mui/material/Button";
import React, {ReactElement} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useCellExpandStore from "../useCellExpandStore";

interface ToggleExpandProps {
  record: Record<string, any>
  expand: ReactElement
}

const ToggleExpand = (props: ToggleExpandProps) => {
  const {
    values: expandCellValues,
    add,
    remove,
  } = useCellExpandStore();

  return <>
    {!expandCellValues[props.record?.id] && <Button
        size={'small'}
        onClick={() => add(props.record?.id, props.expand)}
        endIcon={<ExpandMoreIcon/>}
    >
      Expand
    </Button>}

    {!!expandCellValues[props.record?.id] && <Button
        size={'small'}
        onClick={() => remove(props.record?.id)}
        endIcon={<ExpandLessIcon/>}
    >
      Expand
    </Button>}
  </>
}

export default ToggleExpand