import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {TableSortLabel} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import {VrpTableContextSorting} from "./types";
import {IVrpTableContextMutations} from "./useTableContext";
import {IRowSelectionMutations} from "./useRowSelection";
import React, {createElement, FCC, ReactNode} from "react";
import {SortDirection} from "@mui/material/TableCell/TableCell";
import {TableSortLabelTypeMap} from "@mui/material/TableSortLabel/TableSortLabel";
import VrpTableCell from "./VrpTableCell";

const findActiveSorting = (sorting: VrpTableContextSorting = [], columnName: string) => {
  return sorting?.find((column) => {
    return column['name'] === columnName;
  })
}

interface IVrpTableHead {
  fields: ReactNode
  enableRowSelection?: boolean
  records?: Record<string, any>[]
  sorting?: VrpTableContextSorting
  onChangeSort?: IVrpTableContextMutations['onChangeSort']
  toggleAllSelection?: IRowSelectionMutations['toggleAll']
  selectedCount?: number
}

const VrpTableHead: FCC<IVrpTableHead> = ({fields, records, enableRowSelection,
  selectedCount = 0, toggleAllSelection, sorting, onChangeSort}) => {

  const columnsDefault = React.Children.toArray(fields)
    .filter((field) => {
      // @ts-ignore
      return !field?.props?.newLine
    })

  return <TableHead>
    <TableRow>
      {enableRowSelection && <VrpTableCell padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={selectedCount > 0 && selectedCount < (records?.length ?? 0)}
          checked={(records?.length ?? 0) > 0 && selectedCount === records?.length}
          onChange={(event) => {
            if (!!records?.length) {
              toggleAllSelection?.(event, records);
            }
          }}
        />
      </VrpTableCell>}

      {
        React.Children.toArray(columnsDefault)
          .map((field: any, index) => {
            const activeSorting = findActiveSorting(sorting, field.props.source)

            if (field.props.headerComponent) {
              return <VrpTableCell
                key={index}
                align={field.props.align}
                width={field.props.width}
              >
                {createElement(
                  field.props.headerComponent,
                  {},
                  field.props.label
                )}
              </VrpTableCell>;
            }

            if (!field.props.sortable) {
              return <VrpTableCell
                key={index}
                align={field.props.align}
                width={field.props.width}
              >
                {field.props.label}
              </VrpTableCell>;
            }

            return <VrpTableCell
              key={index}
              align={field.props.align}
              width={field.props.width}
              padding={field.props.disablePadding ? 'none' : 'normal'}
              sortDirection={!!activeSorting ? activeSorting?.direction as SortDirection : false}
            >
              <TableSortLabel
                active={!!activeSorting}
                direction={!!activeSorting ? activeSorting?.direction as TableSortLabelTypeMap['props']['direction'] : 'asc'}
                onClick={() => onChangeSort?.({
                  name: field.props.source,
                  direction: activeSorting?.direction === 'desc' ? 'asc' : 'desc',
                })}
              >
                {field.props.label}
                {!!activeSorting ? (
                  <Box component="span" sx={visuallyHidden}>
                    {activeSorting?.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </VrpTableCell>
          })
      }
    </TableRow>
  </TableHead>
}

export default VrpTableHead