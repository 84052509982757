import React, {FCC, PropsWithChildren, ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import VrpTableHead from "./VrpTableHead";
import VrpTableBody, {RenderRowProps} from "./VrpTableBody";
import Table from "@mui/material/Table";
import VrpTableLoader from "./VrpTableLoader";
import {VrpTableContextSorting} from "./types";
import {IVrpTableContextMutations} from "./useTableContext";
import {IRowSelectionMutations} from "./useRowSelection";
import {TableTypeMap} from "@mui/material/Table/Table";
import FloatHorizontalScroll from "../crud/list/datagrid/FloatHorizontalScroll";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import {createPortal} from "react-dom";
import Paper from "@mui/material/Paper";
import useFloatScroll from "./useFloatScroll";
import VrpTableError from "./VrpTableError";
import VrpTableWithScrollbar from "./VrpTableWithScrollbar";

const Container = styled(Box)`
  position: relative;
  //overflow-x: auto;
  overflow: hidden;
`

export interface IVrpTable {
  loading?: boolean,
  error?: string,
  enableRowSelection?: boolean,
  stickyHeader?: boolean,
  records?: Record<string, any>[] | undefined,
  sorting?: VrpTableContextSorting,
  onChangeSort?: IVrpTableContextMutations['onChangeSort'],
  selectedIds?: any[],
  isRowSelected?: IRowSelectionMutations['isSelected'],
  toggleOneSelection?: IRowSelectionMutations['toggleOne'],
  toggleAllSelection?: IRowSelectionMutations['toggleAll'],
  size?: string,
  rowAlert?(): ReactNode | null
  renderRow?(props: RenderRowProps): ReactNode
  onRowClick?(data: Record<string, any>, id: number): void
  showScroll?: boolean
  scrollContainer?: 'table' | 'page'
}

const VrpTable: FCC<IVrpTable> = (props) => {
  const {children, loading, records, sorting, onChangeSort, enableRowSelection, stickyHeader, selectedIds, isRowSelected, toggleOneSelection, toggleAllSelection, size} = props;
  const floatScroll = useFloatScroll()

  return <Container ref={floatScroll.containerRef}>
    <VrpTableLoader show={loading} />

    <VrpTableWithScrollbar {...props} floatScroll={floatScroll}>
      <Table
          size={size as TableTypeMap['props']['size']}
          stickyHeader={stickyHeader}
      >
        <VrpTableHead
            fields={children}
            records={records}
            sorting={sorting}
            onChangeSort={onChangeSort}
            selectedCount={selectedIds?.length}
            toggleAllSelection={toggleAllSelection}
            enableRowSelection={enableRowSelection}
        />

        {!!props.error && <VrpTableError error={props.error} columnsNumber={React.Children.toArray(children)?.length ?? 1} />}

        <VrpTableBody
            fields={children}
            records={records}
            isRowSelected={isRowSelected}
            toggleOneSelection={toggleOneSelection}
            enableRowSelection={enableRowSelection}
            rowAlert={props.rowAlert}
            renderRow={props.renderRow}
            onRowClick={props.onRowClick}
        />

      </Table>
    </VrpTableWithScrollbar>
  </Container>
}

VrpTable.defaultProps = {
  loading: false,
  enableRowSelection: false,
  stickyHeader: false,
  size: 'medium',
  showScroll: true,
  scrollContainer: 'table'
} as Partial<IVrpTable>



export default VrpTable
