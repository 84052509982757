import get from 'lodash/get'
import React, {FCC, ReactElement, useCallback, useMemo, useState} from "react";
import styled from "@emotion/styled";
import {useRecordContext} from "../../crud/list/useRecordContext";
import Box from "@mui/material/Box";
import ToggleExpand from "./ToggleExpand";
import withEditable from "./withEditable";

const Preformatted = styled(Box)`
  white-space: pre-line;
`

const Nowrap = styled(Box)`
  white-space: nowrap;
  min-height: 16px;
  width: 100%;
`

export interface IVrpTextField {
  source: string
  label?: string
  sortable?: boolean
  preformatted?: boolean
  nowrap?: boolean
  newLine?: boolean
  width?: number
  expand?(): ReactElement | null
  sx?: Record<string, any>
  headerComponent?: FCC
}

const VrpTextField: FCC<IVrpTextField> = ({source, label, sortable = false, nowrap = false, preformatted = true, ...props}) => {
  const record = useRecordContext()

  let tag = <></>
  if (nowrap) {
    tag = <Nowrap sx={props.sx} />
  } else if (preformatted) {
    tag = <Preformatted sx={props.sx} />
  }

  const children = useMemo(() => {
    const value = get(record, source)

    let c
    if (value === null) {
      c = ''
    } else if (typeof value === 'object') {
      c = JSON.stringify(value)
    } else {
      c = value
    }

    if (!!record && !!props.expand?.()) {
      c = <React.Fragment>
        {c}

        <Box sx={{marginLeft: '-10px'}}>
          {/*@ts-ignore*/}
          <ToggleExpand record={record} expand={props.expand?.()} />
        </Box>
      </React.Fragment>
    }

    return c
  }, [record, source])

  return React.cloneElement(tag, {
      children: children
  })
}

export default withEditable<IVrpTextField>(VrpTextField)
