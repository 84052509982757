import {create} from 'zustand'
import {ReactElement} from "react";

interface ICellExpandStore {
  values: Record<string, any>
  add(id: string, expandComponent: ReactElement): void
  remove(id: string): void
  sync(newRows: Record<string, any>): void
  reset(): void
}

const useCellExpandStore = create<ICellExpandStore>((set) => ({
  values: {},
  add: (id, expandComponent) => set((state) => ({ values: {...state.values, [id]: expandComponent}})),
  remove: (id) => set((state) => {
    let values = state.values

    delete values[id]

    return { values: {...values}}
  }),
  sync: (newRows) => set((state) => {
    let existsRows: Record<string, any> = {}

    for (const valuesKey in state.values) {
      const existInNewSet = newRows[valuesKey];
      if (existInNewSet) {
        existsRows[valuesKey] = state.values[valuesKey]
      }
    }

    return { values: existsRows}
  }),
  reset: () => set((state) => ({ values: {}})),
}))

export default useCellExpandStore
