import useScrollStore from "./useScrollStore";
import {Ref, useCallback, useEffect, useRef} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import get from "lodash/get";

const useFloatScroll = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const floatScrollRef = useRef<HTMLDivElement>(null);

  const {frame, setFrame} = useScrollStore()

  const handleScroll = (left: number) => {
    if (!containerRef?.current || typeof window === 'undefined') {
      return
    }

    const position = left * (containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth)
    containerRef.current.scrollLeft = position;
  }

  const getFrameParams = () => {
    const scrollWidth = 500
    const multiplier = get(containerRef, 'current.clientWidth', 0) / get(containerRef, 'current.scrollWidth', 0)
    const thumbWidth = Math.floor(multiplier * scrollWidth)
    const left = get(containerRef, 'current.scrollLeft', 0) / (get(containerRef, 'current.scrollWidth', 0) - get(containerRef, 'current.clientWidth', 0))

    const scrollLeft = left * (scrollWidth - thumbWidth)

    return {
      left: left,
      scrollLeft: scrollLeft,
      scrollWidth: scrollWidth,
      thumbWidth: thumbWidth,
    }
  }

  const handleContainerScroll = useCallback(() => {
    const frame = getFrameParams()

    // @ts-ignore
    floatScrollRef?.current?.setPosition(frame.scrollLeft)
  }, [floatScrollRef, containerRef])

  const handleBodyScroll = useCallback(() => {
    const rectBody = document.body.getBoundingClientRect()
    const visibleWindowBottomLine = window.scrollY + window.innerHeight
    const leftToBottom = Math.floor(rectBody.height - visibleWindowBottomLine)

    let position = 0
    if (leftToBottom > 45) {
      position = 20
    } else {
      position = 85
    }

    let floatHorizontalScrollContainerRef = document.getElementById("floatHorizontalScrollContainer")
    if (floatHorizontalScrollContainerRef) {
      floatHorizontalScrollContainerRef.style.bottom = `${position}px`
    }

  }, [])

  useResizeObserver(containerRef, (entry) => {
    const frame = getFrameParams()

    setFrame(frame)
  })

  useEffect(() => {
    const frame = getFrameParams()

    setFrame(frame)
  }, [floatScrollRef, containerRef, containerRef?.current?.scrollWidth])

  useEffect(() => {
    if (!containerRef?.current) {
      return
    }

    containerRef.current.addEventListener("scroll", handleContainerScroll, { passive: true });

    return () => {
      if (!containerRef.current) {
        return
      }

      containerRef.current.removeEventListener("scroll", handleContainerScroll)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("scroll", handleBodyScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleBodyScroll)
    }
  }, [])

  return {frame, setFrame, handleScroll, handleContainerScroll, containerRef, floatScrollRef}
}

export default useFloatScroll