import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import React from "react";
import {StyledTableRow} from "./VrpTableBody";

interface VrpTableErrorProps {
  columnsNumber: number
  error: string
}

const VrpTableError = (props: VrpTableErrorProps) => {
  return <TableBody>
    <StyledTableRow className={'alert'}>
      {/*@ts-ignore*/}
      <TableCell colSpan={props.columnsNumber + 1}>
        <Typography variant={'caption'} component={Box} p={1} textAlign={'center'}>{props.error}</Typography>
      </TableCell>
    </StyledTableRow>
  </TableBody>
}

export default VrpTableError
