import React, {FCC, ReactNode, useCallback} from "react";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Checkbox from '@mui/material/Checkbox';
import {RecordContext} from "../crud/list/RecordContext";
import {IRowSelectionMutations} from "./useRowSelection";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import get from "lodash/get";
import useCellExpandStore from "./useCellExpandStore";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import VrpTableCell from "./VrpTableCell";
import VrpTableRowAlert from "./VrpTableRowAlert";

interface IVrpTableBody {
  fields: ReactNode,
  rowAlert?(): ReactNode | null,
  renderRow?(props: RenderRowProps): ReactNode,
  enableRowSelection?: boolean,
  listenRowClick?: boolean,
  records?: Record<string, any>[] | undefined,
  isRowSelected?: IRowSelectionMutations['isSelected'],
  toggleOneSelection?: IRowSelectionMutations['toggleOne'],
  toggleAllSelection?: IRowSelectionMutations['toggleAll'],
  onRowClick?(data: Record<string, any>, id: number): void
}

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  '&.alert': {
    backgroundColor: 'rgb(255, 244, 229)',
  },
  '&.odd': {
    backgroundColor: 'rgba(229, 236, 246, 0.5)',
  },
  '&.clickable': {
    cursor: 'pointer',
  },
  '&.dropped': {
    backgroundColor: 'rgba(0,177,252,0.9)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgb(255, 255, 213)',
    '&.odd': {
      backgroundColor: 'rgba(255, 255, 213)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 213)',
    },
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

export interface RenderRowProps {
  record: Record<string, any>
  index: number
  selected: boolean
  child: ReactNode
  onClick?(data: Record<string, any>, id: number): void
}

const VrpTableBody: FCC<IVrpTableBody> = ({
   fields, records,
   toggleOneSelection, isRowSelected,
   enableRowSelection = false,
  ...props
}) => {

  const columnsDefault = React.Children.toArray(fields)
    .filter((field) => {
      // @ts-ignore
      return !field?.props?.newLine
    })

  const columnsNewLine = React.Children.toArray(fields)
    .filter((field) => {
      // @ts-ignore
      return !!field?.props?.newLine
    })

  const {
    values: expandCellValues,
  } = useCellExpandStore();

  const renderRow = useCallback((renderRowProps: RenderRowProps) => {
    if (props.renderRow) {
      return props.renderRow(renderRowProps)
    }

    const isOdd = renderRowProps.index % 2 == 0

    return <StyledTableRow
        hover
        selected={renderRowProps.selected}
        className={clsx({['odd']: isOdd, ['clickable']: !!renderRowProps.onClick})}
        onClick={renderRowProps.onClick ? () => renderRowProps.onClick?.(renderRowProps.record, renderRowProps.index) : undefined}
    >
      {renderRowProps.child}
    </StyledTableRow>
  }, [props.renderRow])

  // нужно что бы при клике на ячейку (когда например меню в ячейке) не срабатывало событие клика на строку
  const onIgnorableCellClick = useCallback((event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    event.stopPropagation()
  }, [])

  if (!Array.isArray(records)) {
    return null;
  }

  if (!(records?.length > 0)) {
    return <TableBody>
      <StyledTableRow className={'odd'}>
        {/*@ts-ignore*/}
        <VrpTableCell colSpan={fields?.length}>
          <Typography variant={'caption'} component={Box} p={1} textAlign={'center'}>No data</Typography>
        </VrpTableCell>
      </StyledTableRow>
    </TableBody>
  }

  return <TableBody>
    {
      records.map((record, index) => {
        const isOdd = index % 2 == 0

        const newLinesColumns = React.Children.toArray(columnsNewLine)
          .filter(field => {
            // @ts-ignore
            return !!get(record, field?.props?.source)
          })

        const borderStyle = (newLinesColumns.length > 0 || !!expandCellValues?.[record?.id]) ?  { borderBottom: 'unset' } : {}

        return <RecordContext.Provider value={record} key={index}>
          {renderRow({
            record,
            index,
            selected: isRowSelected?.(record, index) ?? false,
            // @ts-ignore
            onClick: !!props.onRowClick ? () => props.onRowClick({record}, index) : undefined,
            child: <>
              {enableRowSelection && <VrpTableCell padding="checkbox" sx={borderStyle}>
                <Checkbox
                  color="primary"
                  checked={isRowSelected?.(record, index)}
                  onClick={() => toggleOneSelection?.(record, index)}
                />
              </VrpTableCell>}

              {
                React.Children.toArray(columnsDefault)
                  .map((field: any, index) => {
                    if (!!field?.props?.editable) {
                      {/*@ts-ignore*/}
                      return React.cloneElement(field, {
                        record,
                        key: index,
                        cellProps: {
                          align: field?.props?.align,
                          sx: borderStyle,
                          width: field.props.width
                        }
                      })
                    }

                    return <VrpTableCell
                      key={index}
                      align={field?.props?.align}
                      sx={borderStyle}
                      width={field.props.width}
                      className={`VrpTableCell-root VrpTableCell-${field.props.source}`}
                      onClick={field.props.ignoreRowClick ? onIgnorableCellClick : undefined}
                    >
                      {/*@ts-ignore*/}
                      {React.cloneElement(field, {
                        record,
                      })}
                    </VrpTableCell>;
                  })
              }

              {!!props.rowAlert && <VrpTableRowAlert alert={props.rowAlert}/>}
            </>
          })}

          {newLinesColumns?.length > 0 && React.Children.toArray(newLinesColumns)
            .map((field: any, index) => {

              const borderNewLineStyle = (newLinesColumns.length - 1) === index ? {} : { borderBottom: 'unset' }

              return <StyledTableRow
                selected={isRowSelected?.(record, index) ?? false}
                className={clsx({['odd']: isOdd})}
              >
                <VrpTableCell colSpan={columnsDefault.length} sx={borderNewLineStyle}>
                  {/*@ts-ignore*/}
                  {React.cloneElement(field, {
                    record,
                  })}
                </VrpTableCell>
              </StyledTableRow>
            })
          }

          {!!expandCellValues?.[record?.id] && <StyledTableRow
            className={clsx({['odd']: isOdd})}
          >
            <VrpTableCell colSpan={columnsDefault.length}>
              {expandCellValues[record?.id]}
            </VrpTableCell>
          </StyledTableRow>}
        </RecordContext.Provider>
      })
    }

    </TableBody>
}

export default VrpTableBody