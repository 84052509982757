import Box from "@mui/material/Box";
import TableCell, {TableCellProps} from "@mui/material/TableCell";
import {FCC} from "react";

interface VrpTableCellProps extends TableCellProps {
  ChildProps?: Record<string, any>
  width?: number
}

const VrpTableCell: FCC<VrpTableCellProps> = (props) => {
  const {width, ChildProps, children, ...restProps} = props

  // @ts-ignore
  return <TableCell {...restProps}
    width={width}
    sx={{
      ...props.sx,
      padding: 0,
    }}
  >
    <Box
      className={'TableCell-wrapper-root'}
      sx={{
        padding: '6px 16px',
        position: 'relative',
        wordWrap: 'break-word',
        display: 'inherit',
        width: width,
        minWidth: width,
        ...ChildProps,
      }}
    >
      {children}
    </Box>
  </TableCell>
}

export default VrpTableCell
