import React, {FCC} from "react";
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";

interface IVrpTableLoader {
  show?: boolean
}

const VrpTableLoader: FCC<IVrpTableLoader> = ({show}) => {
  if (!show) {
    return null
  }

  return <Box>
    <LinearProgress />
  </Box>
}

export default VrpTableLoader