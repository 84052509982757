import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import React, {ReactNode} from "react";
import {Tooltip} from "@mui/material";
import {TooltipProps} from "@mui/material/Tooltip";

const TriangleIcon = styled(Box)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #007bff transparent transparent transparent;
`

interface VrpTableRowAlert {
  alert(): ReactNode | null
}

const VrpTableRowAlert = (props: VrpTableRowAlert) => {
  const content = props.alert()
  if (!content) {
    return null
  }

  return <Box
    sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      cursor: 'pointer',
    }}
  >
    <Tooltip title={content}>
      <Box
        sx={{
          width: '20px',
          height: '20px',
        }}
      >
        <TriangleIcon />
      </Box>
    </Tooltip>

  </Box>
}

export default VrpTableRowAlert
