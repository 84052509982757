import {create} from 'zustand'

interface ICellExpandStore {
  frame: Record<string, any> | null
  setFrame(value: Record<string, any>): void
}

const useScrollStore = create<ICellExpandStore>((set) => ({
  frame: null,
  setFrame: (value) => set((state) => ({ frame: value})),
}))

export default useScrollStore
