import React, {FCC, FC, ReactElement, useCallback} from "react";
import {Popover} from "@mui/material";
import {useRecordContext} from "../../crud/list/useRecordContext";
import VrpTableCell from "../VrpTableCell";

export interface EditableProps {
  editable?: ReactElement
  cellProps?: Record<string, any>
}

const withEditable = <P,>(WrappedComponent: FC<P & EditableProps>): FC<P & EditableProps> => {
  return (props: P & EditableProps) => {
    const record = useRecordContext()
    const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);

    const handleClick = useCallback((event: React.MouseEvent<HTMLTableCellElement>) => {
      setAnchorEl(event.currentTarget);
    }, [anchorEl])

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, [anchorEl])

    if (!props.editable) {
      return <WrappedComponent {...props} />
    }

    // @ts-ignore
    const {sx, ...restCellProps} = props.cellProps

    return <>
      <VrpTableCell
        {...restCellProps}
        onClick={handleClick}
        sx={{
          ...sx,
          cursor: 'pointer',
          backgroundColor: anchorEl ? 'rgba(235,236,240)' : 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(235,236,240)'
          },
        }}
      >
        <WrappedComponent {...props} />
      </VrpTableCell>

      {!!props.editable && <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {React.cloneElement(props.editable, {
          record,
          onClose: handleClose,
        })}
      </Popover>}
    </>
  }
}

export default withEditable
